.galerie {
	display: flex;
	align-content: flex-start; /* Ensures rows align at the top and do not spread out */
	align-items: flex-start; /* Prevent children from stretching to fill the container height */
	justify-content: center;
	flex-wrap: wrap;
	width: 100%;
	/* background-color: crimson; */
}

.galerie a {
	color: black !important;
	text-decoration: none !important;
}

.galerie a:visited {
	color: black !important;
}