#lien-back-office {
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 1.25em;
	font-weight: bold;
}

#lien-back-office a {
	background-color: var(--button-bg-color);
	border: none;
	border-radius: 6px;
	color: var(--button-text-color);
	cursor: pointer;
	padding: 0.5em 0.75em;
	transition: background-color 0.3s ease;
}

#lien-back-office a:hover {
	text-decoration: none;
}

#lien-back-office a:visited {
	color: var(--button-text-color);
}

#galeries-connected {
	margin-top: 2em;
}

#galeries-visitor {
	margin-top: 3em;
}

.home-galerie {
	margin-bottom: 2em !important;
}

.color-bar {
	border-left: solid 3px var(--bordeaux);
	border-right: solid 3px var(--bordeaux);
	/* haut | horizontal | bas */
	padding: 0 15px 0;
	margin: 0 auto 0.5em;
	max-width: max-content;
}

.item-visit {
	height: 300px;
	width: 300px;
	margin: 10px !important;
	overflow: hidden;
	flex-shrink: 0;
	border: solid 1px var(--light-grey);
	border-radius: 0.25em;
	box-shadow: 0px 0px 10px 3px var(--light-grey);
}

.item-visit img {
	width: 100%;
	object-fit: cover;
	margin-bottom: 5px;
}

.item-connected {
	width: 300px;
	margin: 10px !important;
	overflow: hidden;
	flex-shrink: 0;
	border: solid 1px var(--light-grey);
	border-radius: 0.25em;
	box-shadow: 0px 0px 10px 3px var(--light-grey);
}

.item-connected img {
	height: 168.75px;
	width: 100%;
	object-fit: cover;
}

.sous-categorie {
	margin: 0 auto !important;
	padding-left: 0.4em;
	padding-bottom: 0.4em;
}