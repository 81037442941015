.create-panier .form-group {
	margin: 0 auto 0.5em !important;
}

#action-buttons {
	margin: 0 auto !important;
}

.action-button {
	display: flex;
	flex-direction: column;
	justify-content: center;
}

.action-button:last-child {
	margin-bottom: 0 !important;
}

.action-button button {
	color: var(--button-text-color);
	cursor: pointer;
    background-color: var(--button-bg-color);
    border: none;
    border-radius: 6px;
	font-size: 1.05em;
	font-weight: bold;
	margin: 0.25em auto 0.5em;
    padding: 0.5em 0.75em;
    transition: background-color 0.3s ease;
}

.server-message {
	margin-bottom: 1.5em !important;
}

/* ---------------------------------------- */

/* small/medium/wide display (ajustements) */

@media only screen and (min-width: 640px) {
	#action-buttons {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
	}

	.action-button {
		margin-bottom: 0 !important;
	}

	.action-button button {
		margin: 0.25em 0.75em 0.5em;
	}
}