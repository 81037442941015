#image {
	display: flex;
	align-items: center;
	justify-content: center;
	overflow: hidden;
	width: 100%;
}

#image img {
	max-height: 700px;
	max-width: 100%;
	object-fit: contain;
}

#add-favourite {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	margin: 0 auto;
}

#add-favourite p {
	/* haut | droit | bas | gauche */
	margin: 0 0.5em 0 0;
	max-width: none;
}

#add-favourite button {
	background: none;
	border: none;
	color: var(--bordeaux);
	cursor: pointer;
	position: relative; /* For the pseudo-element positioning */
}

#add-favourite svg {
	height: 30px;
	width: 30px;
}

/* Pseudo-element for the forward slash oblique bar */
#remove-fav::after {
	content: '';
	position: absolute;
	bottom: 0;
	left: 0;
	width: 140%; /* Slightly more than 100% to cover diagonally */
	height: 5px; /* Thickness of the bar */
	background-color: var(--bordeaux); /* Color of the bar */
	border: 1px solid var(--main-bg-color); /* Border around the bar */
	border-radius: 20px;
	transform: scaleX(0) rotate(-45deg); /* Rotate to make the bar a forward slash */
	transform-origin: left center; /* Transform origin to left center */
	box-sizing: border-box; /* Ensure the border is included in the element's dimensions */
}

/* Hover effect */
#remove-fav:hover::after {
	transform: scaleX(1) rotate(-45deg); /* Scale to full width and keep it rotated */
}

#add-image-panier {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	margin: 0.5em auto;
}

#add-image-input {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	margin: 0 auto;
	width: 100%;
}

#add-image-panier label {
	margin-bottom: 4px;
}

#add-image-panier .custom-select {
	max-width: 400px;
}

.tag {
    display: inline-block; /* Ensures the tag stays on the same line */
    color: black !important;
	cursor: pointer; /* Changes cursor to pointer on hover */
    background-color: var(--light-grey);
	border-radius: 20px;
	/* haut | droit | bas | gauche */
	margin: 5px 10px 5px 0;
    padding: 5px 10px;
	text-decoration: none !important;
    transition: background-color 0.3s ease; /* Smooth transition for hover effect */
}

.tag:hover {
    background-color: #dcdcdc; /* Darkens the background slightly on hover */
}

/* ---------------------------------------- */

/* small display (ajustements) */

@media only screen and (min-width: 640px) {
	#add-favourite svg {
		height: 40px;
		width: 40px;
	}

	#remove-fav::after {
		height: 6px; /* Thickness of the bar */
	}

	#add-image-input {
		flex-direction: row;
	}

	#add-image-input .custom-select {
		padding-bottom: 0;
	}

	#add-image-button {
		/* haut | droit | bas | gauche */
		margin: auto 0 auto 15px;
	}

	.tag {
		/* haut | droit | bas | gauche */
		margin: 0 10px 10px 0;
	}
}