.pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
}

.pagination button {
    background-color: transparent;
    border: 1px solid var(--bordeaux);
    color: var(--bordeaux);
    padding: 5px 10px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 0.9em;
    transition: background-color 0.3s, color 0.3s;
}

.pagination button:hover:not(:disabled) {
    background-color: var(--bordeaux);
    color: #ffffff;
}

.pagination button:disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

.pagination button.active {
    background-color: var(--bordeaux);
    color: #ffffff;
}

.pagination .page-button {
	min-width: 44.031px;
}

.ellipsis {
    border: none;
    background-color: transparent;
    color: var(--bordeaux);
	cursor: not-allowed;
}

/* ---------------------------------------- */

@media only screen and (min-width: 826px) {
	.pagination button {
		font-size: var(--font-size-body);
	}

	.pagination .page-button {
		min-width: 49.5px;
	}
}

/* ---------------------------------------- */

/* medium display (ajustements) */

@media only screen and (min-width: 1140px) {
	.pagination:last-of-type {
		margin-bottom: 0 !important;
	}

	.search-results .pagination {
		justify-content: start;
		margin-left: 10px;
	}
}