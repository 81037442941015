.grille-livre {
	margin: 0 auto 2em !important;
	max-width: 1180px;
}

h3 {
	color: var(--dark-grey);
	font-family: Helvetica;
	font-weight: bold;
	font-size: var(--font-size-h3);
}

.titre-livre p {
	font-family: Helvetica;
	font-weight: bold;
	margin: 0.25em 0 0 !important;
}

.infos {
	text-align: center;
}

.infos p {
	text-align: center !important;
}

.infos img {
	margin: 0 auto 0.5em;
	object-fit: contain;
	width: 80%;
}

/* ---------------------------------------- */

/* small display (ajustements) */

@media only screen and (min-width: 640px) {
	.grille-livre {
		display: grid;
		grid-template-columns: max(300px) 1fr;
		grid-template-rows: min-content 1fr;
		gap: 0 28px;
		grid-auto-flow: row;
		grid-template-areas:
		  "titre-livre titre-livre"
		  "infos resume";
	}

	h3 {
		font-size: var(--font-size-h3-mobile);
	}

	.titre-livre {
		grid-area: titre-livre;
	}

	.infos {
		grid-area: infos;
		margin: 0 !important;
	}

	.infos img {
		margin: 0;
		object-fit: contain;
		width: 100%;
	}

	.resume {
		grid-area: resume;
	}
}

/* ---------------------------------------- */

/* medium display (ajustements) */

@media only screen and (min-width: 1140px) {
	.grille-livre {
		grid-template-columns: max(350px) 1fr;
	}
}

/* ---------------------------------------- */

/* wide display (ajustements) */

@media only screen and (min-width: 1433px) {
	.grille-livre {
		grid-template-columns: max(400px) 1fr;
		gap: 0 35px;
	}
}