#contact-logo {
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 2em auto 0 !important;
	overflow: hidden;
	width: 100%;
}

#contact-logo img {
	max-height: 400px;
	max-width: 100%;
	object-fit: contain;
}