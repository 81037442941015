.password-criteria {
    list-style-type: none;
}

.password-criteria li {
    display: flex;
    align-items: center;
    margin: 0.25rem 0;
}

.password-criteria .valid {
    color: var(--sucess-color);
}

.password-criteria .invalid {
    color: var(--error-color);
}

#form-terms {
	display: flex;
	flex-direction: row;
	margin-bottom: 0.75em;
}

#form-terms input {
	margin-right: 8px;
	transform: scale(1.3);
	vertical-align: middle; /* Align checkbox vertically with the label */
    width: auto;
}

#form-terms.highlight label {
    color: var(--error-color);
}

/* ---------------------------------------- */

/* small/medium/wide display (ajustements) */

@media only screen and (min-width: 640px) {
	.password-criteria {
		margin: 0 0 0.5em 4% !important;
	}
	
	#form-terms input {
		margin-right: 12px;
		transform: scale(1.4);
	}
}