.text-message p {
	text-align: center;
}

.results-count p {
	text-align: center;
}

.item-planche-contact {
	border: solid 1px var(--light-grey);
	border-radius: 0.25em;
	box-shadow: 0px 0px 10px 3px var(--light-grey);
	display: flex;
	align-self: flex-start; /* Prevents the item from stretching */
	flex-direction: column;
	flex-shrink: 0;
	justify-content: space-between; /* Ensures the p is at the bottom */
	margin: 10px !important;
	overflow: hidden;
}

.item-planche-contact .item-thumbnail {
	height: 300px;
	margin: 0;
	/* haut | horizontal | bas */
	padding: 6px 6px 0;
}

.item-planche-contact p {
	margin: 5px auto 5px;
}

.item-thumbnail {
	display: flex;
	justify-content: center; /* Center horizontally */
	align-items: center; /* Center vertically */
	width: 270px;
}

.item-thumbnail p {
	font-size: 0.92em;
	text-align: center !important;	/* Center loading text */
}

.item-planche-contact img {
	width: 100%;
	max-width: 100%; /* Ensures the image fits within its container */
	max-height: 100%; /* Ensures the image does not overflow */
	object-fit: contain;
}

.planche-contact-code-image {
	font-size: 0.9em;
	text-align: center !important;
}

.item-planche-contact .planche-contact-button {
	display: flex;
	justify-content: center;
	margin: 0 auto 9px;
	width: 100%;
}

.planche-contact-button {
	color: var(--bordeaux);
	cursor: pointer;
}

.planche-contact-button svg {
	height: 30px;
	width: 30px;
}

/* ---------------------------------------- */

/* items ajustements */

@media only screen and (min-width: 527px) {
	.item-thumbnail {
		width: 220px;
	}
}

/* ---------------------------------------- */

/* small display (ajustements) */

@media only screen and (min-width: 640px) {
	.planche-contact-button svg {
		height: 36px;
		width: 36px;
	}
}