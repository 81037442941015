
.pdf-viewer {
	display: block;
	height: 700px;
	width: 100%;
	margin: 1em auto auto;
}

.pdf-viewer iframe {
	height: 100%;
	width: 100%;
}

/* ---------------------------------------- */

/* enlever les iframe sur mobile/tablette */

@media screen and (max-device-width: 800px) {
	.pdf-viewer {
		display: none;
	}
}