/*
Display (pour adapter le style du menu et la taille de la police) :
- mobile first: under 640px (mobile style menu)
- small : min 640px (mobile style menu)
- medium: min 1140px
- wide: min 1433px

Mobile max-device-width: 480px
Tablet max-device-width: 800px
*/

:root {
	--main-bg-color: #fefefe;
	--light-grey: #ececec;
	--medium-grey: #585857;
	--dark-grey: #383838;
	--bordeaux: #c00000;
	--dark-bordeaux: #9f0000;

	color-scheme: only light;

	--error-color: red;
	--sucess-color: green;

	--button-bg-color: var(--bordeaux);
    --button-text-color: white;

	--input-border-color: #ccc;
	--input-focus-background-color: #66b2ff;
    --input-focus-border-color: #007bff;

	--font-size-body-mobile: 1em;
	--font-size-h1-mobile: 1.5em;
	--font-size-h2-mobile: 1.25em;
	--font-size-h3-mobile: 1.15em;

	--font-size-body: 1.05em;
	--font-size-h1: 1.75em;
	--font-size-h2: 1.5em;
	--font-size-h3: 1.25em;

	--z-fixed: 1000;
}

*, ::before, ::after {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
}

html {
	font-family: Verdana, sans-serif;
}

body {
	background-color: var(--main-bg-color);
	display: flex;
	flex-direction: column;
	font-size: var(--font-size-body-mobile);
}

.wrapper {
	display: flex;
	flex-direction: column;
	justify-content: center;
	min-height: 100vh;
}

.container {
	max-width: 1700px;
	margin: 0 auto;
}

.page-main {
	max-width: 1700px;
}

.wrapper > * {
	padding: 1em;
}

/* Loader component CSS */
.loader {
    display: flex;
    flex-direction: column;
    align-items: center; /* Center horizontally */
    justify-content: center; /* Center vertically */
    height: 80vh; /* Make sure the loader takes up the full height of the parent */
    text-align: center;
    margin: 0 auto 5em; /* Remove any top margin */
}

.loader img {
    height: 250px;
}

.page-main .loader {
    justify-content: flex-start; /* Align to the top */
    height: auto; /* Remove full-height when inside page-main */
}

.page-header {
	background-color: var(--light-grey);
	box-shadow: 0px 10px 13px -7px #000000, 0px 5px 10px 2px rgba(88,88,87,0);

	/* stick the header at the top of the page */
	position: fixed;
	width: 100%;
	top: 0;
	left: 0;
	z-index: var(--z-fixed);
}

.page-header p {
	margin-bottom: 5px;
}

.page-header .container {
	align-items: center;
	display: grid;	
	grid-template-columns: auto 1fr auto;
	grid-template-rows: min-content 1fr;
	gap: 0.5em 1.25em;
	grid-auto-flow: row;
	grid-template-areas:
	  "logo imagesguenomiller imagesguenomiller"
	  "language-search language-search topnav";
}

.logo {
	grid-area: logo;
	text-align: center;
}

.logo img {
	height: 110px;
	margin: 3px auto 0;
	object-fit: contain;
}

.imagesguenomiller {
	grid-area: imagesguenomiller;
}

#brand {
	color: var(--dark-grey);
	font-family: Helvetica, sans-serif;
	font-size: 1.5em;
	font-weight: 510;
	margin: 0;
}

#subtitle {
	color: var(--dark-grey);
	font-family: Helvetica, sans-serif;
	font-size: 1.15em;
	font-weight: 510;
	margin: 0;
}

.topnav {
	grid-area: topnav;
}

.topnav a {
	color: var(--bordeaux);
	text-decoration: none;
	font-weight: bold;
}

.topnav a:hover {
	text-decoration: underline;
	text-decoration-thickness: 2px;
	text-underline-offset: 5px;
}

.topnav a:visited {
	color: var(--bordeaux);
}

.nav__cta a {
	/* CTA = call to action */
	color: var(--button-text-color);
	background-color: var(--button-bg-color);
	border-radius: 6px;
	padding: 0.5em 0.75em;
	transition: background-color 0.3s ease;
}

.nav__cta a:hover {
	text-decoration: none;
}

.nav__cta a:visited {
	color: var(--button-text-color);
}

.nav__menu {
	display: flex;
	flex-direction: column;
	justify-content: left;
	background-color: hsla(0, 0%, 6%, 0.2);
	backdrop-filter: blur(16px);
	-webkit-backdrop-filter: blur(16px);
	height: 100%;
	width: 70%;
	padding: 6rem 3rem 0;
	position: fixed;
	top: 0;
	right: -100%;
	transition: right 0.4s;
}

.show-menu {
	right: 0;
}

.nav__list {
	list-style-type: none;
	margin: 1em 0 0;
	padding: 0;
}

.nav__item {
	/* haut | horizontal | bas */
	margin: 0 0 0.55em;
}

#nav__toggle,
#nav__close {
	color: var(--dark-grey);
	cursor: pointer;
	font-size: 2.25rem;
}

#nav__toggle {
	display: flex;
	flex-direction: row;
	justify-content: end;
}

.language-search {
	grid-area: language-search;
	align-items: start;
	display: flex;
	flex-direction: column;
}

.icons {
	display: flex;
	flex-direction: row;
	align-items: center;
	min-height: 33px;
}

.icons div {
	margin: 0;
}

.language button {
	padding: 1px;
	background-color: var(--dark-grey);
	border: none;
	cursor: pointer;
	height: 20px;
	width: calc(20px * 5 / 3); /* Automatically calculates the width based on the height and aspect ratio */;
	/* haut | droit | bas | gauche */
	margin: 1px 0.5em 0 0;
	overflow: hidden;
}

.language img {
	display: block;
	margin: auto;
	height: 100%;
	width: 100%;
}

#heart {
	/* haut | droit | bas | gauche */
	margin: 0 0 0 0.3em;
}

#heart svg {
	color: var(--bordeaux);
	height: 30px;
	width: 30px;
}

.search {
	display: flex;
	align-items: center;
	flex-direction: row;
	justify-content: start;
	margin: 0;
	margin-bottom: 0.5em;
	padding: 0;
	width: 100%;
}

#search-bar {
	border: 1px solid var(--input-border-color);
    border-radius: 4px;
	box-sizing: border-box;
	font-size: var(--font-size-body-mobile);
	margin: 0.25em 0;
	padding: 0.25em 0.5em;
	transition: border-color 0.3s, border-width 0.3s;
	height: auto;
	max-width: 350px;
	width: 100%;
}

#search-bar:focus {
	border-color: var(--input-focus-border-color);
    outline: none;
}

.search-bar-error {
	border-color: var(--error-color);
	color: var(--error-color);
}

#submit-icon {
	background-color: var(--light-grey);
	background-image: url("../assets/loupe.png");
	background-size: cover;
	border: none;
	color: transparent;
	cursor: pointer;
	/* haut | droit | bas | gauche */
	margin: 0 0 0 10px;
	height: 24px;
	width: 24px;
}

.page-main {
	flex-grow: 1;
	margin: 0 1%;
	padding-top: 260px;
	/* Header height 201 + padding 16 x 2 + marge 27 */
}

h1 {
	color: var(--dark-grey);
	font-family: Helvetica, sans-serif;
	font-weight: bold;
	font-size: var(--font-size-h1-mobile);
	margin-bottom: 1em;
	text-align: center;
}

h2 {
	color: var(--dark-grey);
	font-family: Helvetica, sans-serif;
	font-size: var(--font-size-h2-mobile);
	font-weight: bold;
	margin-bottom: 0.75em;
	text-align: center;
}

.page-main div {
	margin-bottom: 1em;
}

.page-main p {
	/* haut | horizontal | bas */
	margin: 0 auto 0.5em;
	max-width: 70ch;
	text-align: left;
	text-rendering: optimizeLegibility;
	white-space: pre-line;
}

.error-message {
	color: var(--error-color);
	text-align: center !important;
}

.success-message {
	color: var(--sucess-color);
	text-align: center !important;
}

.page-main ul {
	/* haut | droit | bas | gauche */
	margin: 0 auto 0.5em 5%;
	max-width: 70ch;
	text-align: left;
	text-rendering: optimizeLegibility;
	white-space: pre-line;
}

.page-main a {
	color: var(--bordeaux);
	text-decoration: none;
}

.page-main a:hover {
	text-decoration: underline;
}

.page-main a:visited {
	color: var(--bordeaux);
}

.lien-nav {
	display: block;
	font-size: 1.1em;
	margin-bottom: 2em;
	text-align: center;
}

.lien-nav a {
	color: var(--bordeaux);
	text-align: center;
	text-decoration: none;
	font-weight: bold;
}

.lien-nav a:hover {
	text-decoration: underline;
	text-decoration-thickness: 2px !important;
	text-underline-offset: 5px;
}

.lien-nav a:visited {
	color: var(--bordeaux);
}

#inscription {
	display: block;
	font-size: 1.25em;
	font-weight: bold;
	margin: 1.5em auto 0;
	text-align: center;
}

#inscription a {
	background-color: var(--button-bg-color);
	border: none;
	border-radius: 6px;
	color: var(--button-text-color);
	cursor: pointer;
	padding: 0.5em 0.75em;
	transition: background-color 0.3s ease;
}

#inscription a:hover {
	text-decoration: none;
}

#inscription a:visited {
	color: var(--button-text-color);
}

.construction {
	text-align: center;
}

.page-footer {
	background-color: var(--medium-grey);
	color: var(--light-grey);
	margin: 20px 0 0;
}

.page-footer .container {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	font-size: 0.9em;
	margin: 0 auto;
}

.bottomnav a {
	color: var(--light-grey);
	flex-direction: row;
	font-weight: bold;
	margin-right: 20px;
	text-align: center;
	text-decoration: none;
}

.bottomnav a:visited {
	color: var(--light-grey);
}

#copyright {
	margin-top: 10px;
	text-align: center;
}

#reseaux div {
	align-items: center;
	display: flex;
	flex-direction: row;
	margin-top: 10px;
}

#reseaux a {
	color: var(--light-grey);
	margin: auto;
	text-decoration: none;
}

#reseaux a:visited {
	color: var(--light-grey);
}

#reseaux img {
	height: 35px;
	width: auto;
	margin-left: 10px;
}

/* Scroll Back To Top Button */
.scroll-to-top {
	background-color: var(--button-bg-color) !important;
	bottom: 25px !important;
	right: 25px !important;
}

/* ---------------------------------------- */

/* small display (ajustements) */

@media only screen and (min-width: 640px) {
	body {
		font-size: var(--font-size-body);
	}

	.container {
		padding: 0 4%;
	}

	.page-header .container {
		align-items: start;
		gap: 1em 1.25em;
	}

	.logo img {
		height: 125px;
	}

	.imagesguenomiller {
		margin-top: 1.25em;
	}

	#brand {
		font-size: 1.85em;
	}

	#subtitle {
		font-size: 1.5em;
	}

	.nav__menu {
		width: 50%;
	}

	.icons {
		min-height: 34px;
	}

	.search {
		margin-bottom: 0.75em;
	}

	#search-bar {
		font-size: var(--font-size-body);
		margin: auto 0;
		max-width: 400px;
	}

	#submit-icon {
		height: 30px;
		width: 30px;
	}

	.page-main {
		margin: 0 auto;
		padding: 287.797px 4% 0;
		/* Header height 227.197 + padding 16.8 x 2 + marge 27 */
		width: 100%;
	}

	h1 {
		font-size: var(--font-size-h1);
		text-align: left;
	}

	h2 {
		font-size: var(--font-size-h2);
	}

	.page-main div {
		margin-bottom: 1.25em;
	}

	.page-main p {
		/* haut | horizontal | bas */
		margin: 0 auto 0.75em;
	}

	.page-main ul {
		/* haut | horizontal | bas */
		margin: 0 auto 0.5em;
	}

	.lien-nav a:hover {
		text-underline-offset: 6px;
	}

	#reseaux {
		display: flex;
		flex-direction: row;
	}

	#reseaux div {
		/* haut | droit | bas | gauche */
		margin: 10px 0 0 10px;
	}

	/* Scroll Back To Top Button */
	.scroll-to-top {
		bottom: 60px !important;
		right: 40px !important;
	}
}

/* ---------------------------------------- */

/* medium display (ajustements) */

@media only screen and (min-width: 1140px) {
	.page-header .container {
		padding: 0 8.5%;
	}

	.page-header .container {
		align-items: center;
		gap: 1em 1.5em;
		grid-template-areas:
		  "logo imagesguenomiller language-search"
		  "topnav topnav topnav";
	}

	.page-header {
		padding-bottom: 0.75em;
	}

	.logo img {
		height: 180px;
	}
	
	.topnav a:hover {
		text-underline-offset: 6px;
	}

	.nav__cta {
		/* haut | horizontal | bas */
		margin: 0 0 0.55em;
	}

	.nav__menu {
		align-items: center;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		background-color: var(--light-grey);
		backdrop-filter: none;
		-webkit-backdrop-filter: none;
		width: 100%;
		padding: 0;
		position: relative;
		right: 0;
		transition: none;
	}

	.nav__list {
		display: flex;
		flex-direction: row;
		justify-content: left;
		margin: 0;
	}

	.nav__item {
		/* haut | droit | bas | gauche */
		margin: 0 1.5em 0.55em 0;
	}

	#nav__toggle,
	#nav__close {
		display: none;
	}

	.language-search {
		align-items: end;
	}

	.language button {
		/* haut | droit | bas | gauche */
		margin: 1px 0 0 0.75em;
	}

	#heart {
		/* haut | droit | bas | gauche */
		margin: 0 0 0 0.85em;
	}

	#heart svg {
		height: 40px;
		width: 40px;
	}

	.search {
		font-size: 1.1em;
		justify-content: end;
	}

	#submit-icon {
		height: 33px;
		width: 33px;
	}

	.page-main {
		padding: 290.422px 10% 0;
		/* Header height 234.022 + padding-top 16.8 + padding-bottom 12.6 + marge 27 */
	}

	.page-footer {
		margin-top: 40px;
	}

	.page-footer .container {
		flex-direction: row;
		font-size: 0.9em;
		justify-content: space-between;
	}

	#copyright {
		margin-top: 0;
	}

	#reseaux div {
		/* haut | droit | bas | gauche */
		margin: 0 0 0 10px;
	}
}

/* ---------------------------------------- */

/* wide display (ajustements) */

@media only screen and (min-width: 1433px) {
	.page-header .container {
		padding: 0 125px;
	}

	.page-footer .container {
		padding: 0 125px;
	}

	.page-header .container {
		gap: 0.25em 2em;
		grid-template-areas:
		  "logo imagesguenomiller language-search"
		  "logo topnav topnav";
	}

	.imagesguenomiller {
		margin-bottom: 55px;
	}

	#search-bar {
		width: 400px;
	}

	.page-main {
		padding: 243.391px 142px 0;
		/* Header height 186.991 + padding-top 16.8 + padding-bottom 12.6 + marge 27 */
	}
}

/* ---------------------------------------- */

/* "decoller" le menu si mobile ou iPad en mode paysage */

/* For Mobile Portrait View */
@media screen
  and (max-device-width: 480px)
  and (orientation: portrait) {
	.page-header {
		position: relative;
	}

	.page-main {
		padding-top: 27px;
	}
}

/* For Mobile/iPad Landscape View */
@media screen
  and (max-device-width: 800px)
  and (orientation: landscape) {
	.page-header {
		position: relative;
	}

	.page-main {
		padding-top: 27px;
	}
}

/* ---------------------------------------- */

/* Gérer le dark mode forcé (ne fonctionne pas forcément) */

@media (forced-colors: active) {
	body {
		forced-color-adjust: none;
	}
}