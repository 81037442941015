/* Modal overlay */
.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black background */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1001; /* Ensure the modal is on top of other content */
    pointer-events: none; /* Disable pointer events on the background */
	font-size: var(--font-size-body-mobile);
}

.modal p {
	text-align: center !important; /* Center the text */
	white-space: pre-line;
}

/* Modal content */
.modal-content {
    background-color: var(--main-bg-color); /* White background for the modal */
    padding: 25px;
    border-radius: 8px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
    max-width: 450px; /* Reduced max width for the modal */
    width: 90%; /* Still responsive but with a narrower default */
    pointer-events: auto; /* Enable pointer events only on the modal content */
    max-height: 80vh; /* Ensure modal doesn't exceed 80% of the viewport height */
    overflow-y: auto; /* Enable scrolling if content exceeds max-height */
}

/* Button container for better layout control */
.modal-buttons {
    display: flex;
    flex-direction: column; /* Stack buttons vertically by default */
    align-items: center; /* Center the buttons horizontally */
    gap: 15px; /* Space between the buttons */
	margin-top: 20px;
}

@media only screen and (min-width: 375px) {
    .modal-buttons {
        flex-direction: row; /* Change to horizontal layout on wider screens */
        justify-content: center;
    }
}

/* Modal buttons */
.modal-content button {
    padding: 10px 18px;
    border: none;
    border-radius: 4px;
    background-color: var(--bordeaux); /* Primary red color */
    color: white;
    cursor: pointer;
	font-size: var(--font-size-body-mobile);
}

#cancel {
    background-color: #6c757d; /* Secondary grey color for the Cancel button */
}

/* Button hover effects */
.modal-content button:hover {
    background-color: var(--dark-bordeaux); /* Darker red on hover */
}

#cancel:hover {
    background-color: #5a6268; /* Darker grey on hover */
}

/* ---------------------------------------- */

/* small/medium/wide display (ajustements) */

@media only screen and (min-width: 640px) {
	.modal {
		font-size: var(--font-size-body);
	}

	.modal-content button {
		font-size: var(--font-size-body);
	}
}
