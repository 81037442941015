#form-open,
#form-close {
	color: var(--dark-grey);
	cursor: pointer;
	font-size: 2.25rem;
	margin: 0 auto;
	text-align: center;
}

#search-container {
	margin-bottom: 0;
}

.search-form .form-group {
    margin-bottom: 0.5em !important;
}

#search-buttons {
	display: flex;
	flex-direction: row;
	justify-content: center;
	margin: 0;
	padding: 0;
}

#search-buttons button {
	margin: 0.25em 0.75em 0.5em;
}

#search-reset {
	background-color: var(--main-bg-color);
	border: solid 3px var(--bordeaux);
	color: var(--bordeaux)
}

#list-characters {
	max-width: 500px;
	margin-bottom: 1.5em;
}

/* ---------------------------------------- */

/* medium display (ajustements) */

@media only screen and (min-width: 1140px) {
	#form-open,
	#form-close {
		display: none;
	}

	#search-container {
		display: grid;
		grid-template-columns: minmax(300px, 400px) 1fr;
		grid-template-rows: min-content 1.5fr;
		gap: 0 1.25em;
		grid-template-areas:
		  "search-form search-results";
	}

	.search-form {
		grid-area: search-form;
	}

	.search-form .form-group {
		margin-bottom: 0.75em !important;
	}

	#list-characters {
		margin-bottom: 0;
	}
	
	.search-results {
		grid-area: search-results;
		min-width: 595px;
	}

	.search-results h2 {
		margin-left: 10px;
		text-align: left;
	}

	.search-results .text-message {
		margin-left: 10px !important;
	}

	.search-results .planche-contact {
		justify-content: start;
    	overflow-y: scroll; /* Enable vertical scrolling */
	}

	.search-results .results-count {
		margin-left: 10px;
	}

	.search-results .results-count p {
		max-width: none;
		text-align: left;
	}
}