#auteur {
	padding-right: 4.5em;
	text-align: right;
}

.item {
	height: 300px;
	width: 300px;
	margin: 10px !important;
	overflow: hidden;
	flex-shrink: 0;
	border: solid 1px var(--light-grey);
	border-radius: 0.25em;
	box-shadow: 0px 0px 10px 3px var(--light-grey);
}

.item img {
	width: 100%;
	object-fit: cover;
	margin-bottom: 5px;
}

/* ---------------------------------------- */

/* medium display (ajustements) */

@media only screen and (min-width: 1140px) {
	#auteur {
		padding-right: 2.5em;
	}
}