.champ {
	border-bottom: 1px solid var(--medium-grey); /* Ensure border is applied in mobile view */
	margin-bottom: 1em;
	padding-bottom: 1em;
}

.champ:last-child {
	border-bottom: none; /* Ensure no border on the last item */
}

.champ p {
	margin: 0;
}

.champ-nom {
	font-weight: bold; /* Makes the field names bold */
	margin-bottom: 0.35em !important;
}

.champ-value {
	margin-bottom: 0 !important;
}

/* ---------------------------------------- */

/* small display (ajustements) */

@media only screen and (min-width: 640px) {
	#notice-container {
		display: grid;
		grid-template-columns: 0.8fr 2.2fr; /* 1fr for the names, 2fr for the values */
		gap: 20px 20px; /* vertical | horizontal */
		margin: 0 auto; /* Center the container on larger screens */
		max-width: 800px; /* Optional: to control the overall width */
	}
	
	.champ {
		display: contents; /* Allows grid items to behave like inline content */
		border-bottom: none;
		margin: 0;
		padding: 0;
	}

	.champ div {
		margin: 0;
	}
	
	.champ-nom {
		grid-column: 1; /* Place .champ-nom in the first column */
		margin-bottom: 0 !important;
	}
	
	.champ-value {
		grid-column: 2; /* Place .champ_value in the second column */
		margin-bottom: 0 !important;
	}
}