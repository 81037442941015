form input {
    border: 1px solid var(--input-border-color);
    border-radius: 4px;
	box-sizing: border-box;
	font-size: var(--font-size-body-mobile);
	margin: 0.25em 0;
	padding: 0.25em 0.5em;
	transition: border-color 0.3s, border-width 0.3s;
    width: 100%;
}

form input.error {
    border-color: var(--error-color);
	font-size: var(--font-size-body-mobile);
}

form input:focus {
    border-color: var(--input-focus-border-color);
    outline: none;
}

form p {
	margin: 0 !important;
}

.form-account .form-group {
    margin-bottom: 0.75em !important;
}

#submit-wrapper {
    text-align: center;
}

#submit-wrapper p {
	text-align: center;
}

form button {
    color: var(--button-text-color);
	cursor: pointer;
    background-color: var(--button-bg-color);
    border: none;
    border-radius: 6px;
	font-size: 1.05em;
	font-weight: bold;
	margin: 0.25em auto 0.5em;
    padding: 0.5em 0.75em;
    transition: background-color 0.3s ease;
}

.error {
    color: var(--error-color);
    font-size: 0.875em;
    margin-top: 0.25em;
}

.password-wrapper {
	align-items: center;
    display: flex;
	margin: 0.25em 0 !important;
	position: relative;
}

.password-wrapper input {
    width: 100%;
}

.password-toggle {
    position: absolute;
    right: 0;
    background: none;
    border: none;
    cursor: pointer;
    color: #333;
    font-size: 1.2em;
    margin: 0 10px;
}

/* ---------------------------------------- */

/* small/medium/wide display (ajustements) */

@media only screen and (min-width: 640px) {
	form {
		margin: 0 auto;
		max-width: 500px;
	}

	form input {
		font-size: var(--font-size-body);
	}

	form input.error {
		font-size: var(--font-size-body);
	}

	form button {
		margin: 0.4em auto 1em;
	}

	.error {
		font-size: 0.925em;
	}

	.password-toggle {
		font-size: 1.25em;
	}
}

/* ---------------------------------------- */
/* ---------------------------------------- */

/* Custom drop-down menu */

.custom-dropdown {
    position: relative;
    width: 100%;
    margin: 0 !important;
}

.custom-dropdown-list {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    max-height: 150px; /* Set the max height for the dropdown */
    overflow-y: auto; /* Enable scrolling */
    border: 1px solid var(--input-border-color);
    border-radius: 4px;
    background-color: #fff;
    z-index: 1000;
    margin: 0 !important;
    padding: 0;
    list-style: none;
}

.custom-dropdown-item {
    padding: 0.25em 0.5em;
    cursor: pointer;
    transition: background-color 0.2s ease;
}

.custom-dropdown-item:hover {
    background-color: var(--input-focus-background-color);
}

/* ---------------------------------------- */

/* CustomDropdown only */

.custom-select {
	padding-bottom: 0.25em;
}

.custom-select:focus {
    outline: none; /* Remove default outline */
}

.custom-select:focus .custom-dropdown-header {
    border-color: var(--input-focus-border-color);
}

.custom-dropdown-header {
	background-color: #fff;
    border: 1px solid var(--input-border-color);
    border-radius: 4px;
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: space-between;
	font-size: var(--font-size-body-mobile);
	margin: 0.25em 0 !important;
	padding: 0.25em 0.5em;
	transition: border-color 0.3s, border-width 0.3s;
}

.custom-dropdown-header.error {
	border-color: var(--error-color);
}

.dropdown-arrow {
    transition: transform 0.3s ease;
}

.dropdown-arrow.open {
    transform: rotate(-180deg);
}

/* ---------------------------------------- */

/* small display (ajustements) */

@media only screen and (min-width: 640px) {
	.custom-dropdown-list {
		max-height: 200px; /* Set the max height for the dropdown */
	}
}

/* ---------------------------------------- */

/* medium display (ajustements) */

@media only screen and (min-width: 1140px) {
	.custom-dropdown-list {
		max-height: 300px; /* Set the max height for the dropdown */
	}
}

/* ---------------------------------------- */
/* ---------------------------------------- */

/* Custom decennie input */

.custom-decade-selector {
	border: 1px solid var(--input-border-color);
    border-radius: 4px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
	justify-content: center;   
	margin: 0.25em 0 !important;
    padding: 0.25em 0;
    transition: border-color 0.3s, border-width 0.3s;
    width: 100%;
}

.custom-decade-selector.focused {
    border-color: var(--input-focus-border-color);
    background-color: var(--input-focus-background-color);
}

.custom-decade-selector button {
    background: none;
	color: var(--button-bg-color);
    font-size: 1.75em;
	padding: 6px 0 0 !important;
}

.custom-decade-selector .decrement-button {
	/* haut | droit | bas | gauche */
	margin: 0 0.3em 0 0.4em;
}

.custom-decade-selector .increment-button {
	/* haut | droit | bas | gauche */
	margin: 0 0 0 0.3em;
}

.custom-decade-selector .remove-button {
	/* haut | droit | bas | gauche */
	margin: 0 0.4em 0 0.3em;
}

.custom-decade-selector button:hover {
    color: var(--dark-bordeaux);
}

.custom-decade-selector input {
	max-width: 300px;
	text-align: center;
}