#source-filters {
	margin: 0 auto 1em; /* Center the container on larger screens */
}

#source-filters button {
	background: none;
	color: var(--button-bg-color);
	font-size: 1.75em;
	padding: 6px 0 0 !important;
}

#source-filters button:hover {
    color: var(--dark-bordeaux);
}

#source-filters .remove-button {
	/* haut | droit | bas | gauche */
	margin: 0 0 0 0.3em;
}

/* ---------------------------------------- */

/* mobile display only */

@media only screen and (max-width: 639px) {
	#source-filters {
		max-width: 300px;
	}
	
	.source-champ {
		display: grid;
		align-items: center;
		grid-template-columns: 1fr auto;
		gap: 0; /* vertical | horizontal */
		grid-template-areas:
		"nom nom"
		"input button";
		margin-bottom: 0.5em !important;
	}

	.source-champ-nom {
		grid-area: nom;
	}

	.source-champ-input {
		grid-area: input;
	}

	.remove-button {
		grid-area: button;
	}
}

/* ---------------------------------------- */

/* small display (ajustements) */

@media only screen and (min-width: 640px) {
	#source-filters {
		display: grid;
		align-items: center;
		grid-template-columns: auto 1fr auto;
		gap: 10px 0; /* vertical | horizontal */
	}

	#source-date_parution {
		min-width: 155px;
	}

	.source-champ {
		display: contents; /* Allows grid items to behave like inline content */
	}
	
	.source-champ-nom {
		grid-column: 1;
		margin-right: 0.75em;
	}

	.source-champ-input {
		grid-column: 2;
	}

	#source-filters .remove-button {
		grid-column: 3;
	}
}